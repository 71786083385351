import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SocialMedia from "./social-media"
//import SocialLinks from "../components/social-links";
//import AdminLinks from "./admin-links";

const Footer = () => {
  const { allStrapiFooter } = useStaticQuery(graphql`
    query {
      allStrapiFooter {
        nodes {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: AUTO
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
          description
          copyRight
          reserved
          designedBy
          socialMeadiaLink {
            name
            icon
            url
            style
          }
          sections {
            label
          }
          body {
            sections {
              label
              links {
                name
                url
              }
            }
          }
        }
      }
    }
  `)

  const footerContent = allStrapiFooter.nodes
  const body = footerContent[0].body
  const socialMediaLinks = allStrapiFooter.nodes[0].socialMeadiaLink
  const image = allStrapiFooter.nodes[0].image

  const sections = body.map(section => {
    //console.log("sections: ", section)
    return section
  })

  // const links = sections[0].sections.map(link => {
  //   //console.log("Link: ", link)
  //   return link
  // })
  //console.log("Links: ", links)
  //console.log("Footer Content - Nodes: ", allStrapiFooter)
  //console.log("Footer Content - Body: ", body)
  //console.log("Footer Content - Image: ", image)
  //console.log("Footer Content - Sections: ", sections)
  //console.log("Footer Content - socialMeadiaLink: ", socialMediaLinks)

  const currentYear = new Date().getFullYear()

  return (
    <footer className="uk-section uk-section-secondary uk-padding-remove">
      <div className="uk-container uk-margin-medium-top uk-margin-medium-bottom">
        <div className="uk-grid uk-grid-large" uk-grid="true">
          <div className="uk-width-1-2@m">
            <div className="uk-width-auto uk-margin-small-bottom">
              <GatsbyImage
                image={getImage(image.localFile)}
                alt={image?.alternativeText}
                style={{ maxWidth: "160px" }}
              />
            </div>
            <div
              className="uk-margin-bottom uk-margin-small-top"
              style={{
                color: "#FFFFFF",
                //fontWeight: "500",
                //fontSize: "20px",
              }}
            >
              {footerContent[0].description} {/* <span>THANK YOU</span> */}
            </div>
            <div className="uk-flex uk-flex-wrap uk-margin">
              <h4
                className="gradient-text-footer2 uk-margin-right"
                style={{ fontWeight: "700" }}
              >
                Let's Connect
              </h4>
              <SocialMedia socialMediaLinks={socialMediaLinks} />
              {/* <AdminLinks /> */}
            </div>
          </div>

          {sections.map((section, index) => {
            //console.log("Section: ", section.sections[0])
            return (
              <div key={index} className="uk-width-1-6@m">
                <h5
                  className="gradient-text-footer2"
                  style={{ fontWeight: "800" }}
                >
                  {section.sections[0].label}
                </h5>
                {section.sections.map((section, index) => {
                  //console.log("Section: ", section.links)

                  return (
                    <ul key={index} className="uk-list">
                      {section.links.map((link, index) => {
                        //console.log("Links: ", link)
                        return (
                          <li key={index}>
                            <Link
                              to={link.url}
                              alt={link.name}
                              className="hover-underline-animation "
                              style={{
                                textDecoration: "none",
                                //color: "#617F7F",
                              }}
                            >
                              {link.name}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )
                })}
              </div>
            )
          })}
        </div>

        <div className="uk-flex uk-flex-between uk-flex-wrap">
          <div className="uk-margin-top uk-margin-bottom gradient-text-footer2">
            <span>
              {footerContent[0].copyRight} {currentYear}{" "}
              {footerContent[0].reserved}
            </span>
          </div>

          <div className="uk-margin-top uk-margin-bottom">
            <a
              className="adu-footer-link gradient-text-footer2"
              href="https://adudev.co.uk"
              target="_blank rel=”noopener”"
            >
              {footerContent[0].designedBy}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
